<template>
  <div class="pa-2">

    <page-card
        cardTitle="Page 6 & 7"
        card-subtitle="No customisation required"
    >

      <v-container>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-img
                src="@/assets/img/page_6.jpg"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-img
                src="@/assets/img/page_7.jpg"
            />
          </v-col>
        </v-row>
      </v-container>


    </page-card>

  </div>
</template>

<script>
import PageCard from "@/components/PageCard";

export default {
  name: "Page6-7",
  components: {
    PageCard
  },
  data: () => ({
    pageName: "p6-7",
  }),
}
</script>
